<template>
    <div class="container-fluid w-100" style="min-height: 30rem; ">
        <div class="card p-2" style="height: calc(100dvh - 7rem);">
            <VuePerfectScrollbar :settings="{ suppressScrollX : false }">
                <staffShift class="w-100" :isManagement="true" />
            </VuePerfectScrollbar>
        </div>
    </div>
</template>

<script>
    import lazyLoadComponent from "@/scripts/lazyLoadComponent";
    import SkeletonBox from "@/components/SkeletonBox";
    export default {
        components: {
            staffShift: lazyLoadComponent({
                componentFactory: () => import("@/views/dashboard/components/staffShift.vue"),
                loading: SkeletonBox,
            }),
        }
    }
</script>